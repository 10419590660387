.tag {
  border-radius: 3px;
  margin-right: 10px;
  padding: 5px 10px;
  vertical-align: middle;
  display: inline-block;
  background: #ebeef0;
  color: white;
  text-align: center;
}

.tag.complete { background: #64BA64; color: white;font-weight: bold; } 

li { list-style: none; }

#loginform { width: 200px; margin: 100px auto; }

div#status {font-size: 200%;color: #197919;text-align: center;font-weight: bold; margin-top: 10px;}


h1 { text-align: center; } 
div#loading { width: 100px;display: block;margin: 10px auto;} 
div#controls { width: 200px; display: block; margin: 10px auto; } 
#barcode input {width: 200px;margin: 10px auto;display: block;} 

#tracking-company-prompt label { width: 200px; display: block; margin: 10px auto; text-align: center; } 
#tracking_company_prompt { width: 200px; display: block; margin: 0px; } 

ul { padding: 0; margin: 20px auto; max-width: 600px; }
ul#line-items li { border-top: 1px solid #ebeef0;  padding: 8px; overflow: hidden; }

ul#line-items li span { float: right; } 
ul#line-items li.complete {  background: #64BA64; color: white;}
ul#line-items li.over {  background: #ef6c64; color: white; font-weight: bold;}
ul#line-items li span.toGo { font-size: 80%; color: #64BA64; width: 100%; }
ul#line-items li.partial {  background: #e8f7e8; }

img.li-img { float: left; width: 60px; margin: 0 10px; }

div#order-details { max-width: 600px; margin: 0px auto; } 

div#help-notice { margin-top: 20px; } 

ul#breadcrumbs { display:flex; justify-content: center; width: 100%;} 

div.app-content {padding: 10px;}

div.hint { text-align: center; font-size: 0.8em;  color: #555;    margin: 10px;} 

div#settings { width: 80%; height: 300px; z-index: 99; border: 1px solid #ccc; background-color: #fff; position: absolute; left: 40px; top: 40px; padding: 10px;  }

.btn {  font-size: 1.14286rem;
    line-height: 1.71429rem;
    font-weight: 400;
    text-transform: initial;
    letter-spacing: initial;
    line-height: 1.14286rem;
    box-sizing: border-box;
    cursor: pointer;
    display: inline-block;
    padding: 9px 15px;
    margin: 0;
    border-radius: 3px;
    height: auto;
    white-space: nowrap;
    text-transform: none;
    font-family: inherit;
    font-weight: normal;
    position: relative;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
    background-color: #0078bd;
    color: #ffffff;
    border: 1px solid #0078bd;
    text-decoration: none;
}

.btn:hover {
    border: 1px solid #0069a6;
    background-color: #0069a6;
    color: #ffffff;
    text-decoration: none;
}


.hidden { display: none; } 

.alert-notice {
    color: #3c763d;
    background-color: #dff0d8;
    border-color: #d6e9c6;
}

.alert-danger {
    color: #a94442;
    background-color: #f2dede;
    border-color: #ebccd1;
}

.alert {
    padding: 15px;
    margin-bottom: 20px;
    border: 1px solid transparent;
    border-radius: 4px;

}

.settings-desc { font-size: 75%; } 

#ship-without-tracking:hover {
    background: linear-gradient(180deg,#f9fafb,#f4f6f8);
    border-color: #c4cdd5;
}

#buttons { overflow:hidden; }

#cog { float:left; }

.function-button {
    fill: #637381;
    position: relative;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    min-height: 40px;
    min-width: 85px;
    margin: 5px;
    padding: 10px;
    background: linear-gradient(180deg,#fff,#f9fafb);
    border: 1px solid #c4cdd5;
    box-shadow: 0 1px 0 0 rgba(22,29,37,.05);
    border-radius: 3px;
    line-height: 1;
    color: #212b36;
    text-align: center;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    text-decoration: none;
    transition-property: background,border,box-shadow;
    transition-duration: .2s;
    transition-timing-function: cubic-bezier(.64,0,.35,1);
}

.left-button {float:left; }
.right-button {float:right; }

div#current_location {display: block;        border-bottom: 1px solid #ccc;            padding-bottom: 10px;                margin-bottom: 10px; }
div#current_location a {font-size: 12px; }

div#detail-controls {
    display: flex;
    flex-direction: column;
    max-width: 400px;
    margin: 0 auto;
}


